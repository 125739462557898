import { useState, forwardRef, ReactElement, useEffect, useCallback } from 'react';
import { Responsive, WidthProvider, Layouts, Layout } from 'react-grid-layout';
import { useTheme, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  capitalize,
  Card,
  CardHeader,
  CardMedia,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { Settings as SettingsIcon, ExpandMore as ExpandIcon } from '@mui/icons-material';
import { STORAGE_KEY_DASHBOARD_LAYOUT } from '@localstack/constants';

import { singlify } from '@localstack/services';
import { WidgetOptions, WidgetDimensions } from '@localstack/types';

import { CustomerLayout } from '~/layouts';

import { OnboardingWidget, OnboardingWidgetOptions } from './widgets/OnboardingWidget';

import {
  FavoritesWidget,
  FavoritesWidgetOptions,
  StacksWidgetOptions,
  StacksWidget,
} from './widgets';
import { WelcomeWidget, WelcomeWidgetOptions } from './widgets/WelcomeWidget';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface WidgetProps {
  children?: JSX.Element;
  removeCallback: (key: string) => void;
  editing?: boolean;
}

const WidgetWrapper = (Component: React.FC) =>
  // eslint-disable-next-line react/display-name
  forwardRef(({ children, ...props }: WidgetProps, ref) => (
    // eslint-disable-next-line
    // @ts-ignore
    <Component {...props} innerref={ref}>
      {children}
    </Component >
  ));


interface LayoutInterface {
  version: string;
  layouts: typeof DEFAULT_LAYOUTS;
}

const widgets = {
  [WelcomeWidgetOptions.i]: {
    widget: WidgetWrapper(WelcomeWidget),
    widgetOptions: WelcomeWidgetOptions,
  },
  [OnboardingWidgetOptions.i]: {
    widget: WidgetWrapper(OnboardingWidget),
    widgetOptions: OnboardingWidgetOptions,
  },
  [FavoritesWidgetOptions.i]: {
    widget: WidgetWrapper(FavoritesWidget),
    widgetOptions: FavoritesWidgetOptions,
  },
  [StacksWidgetOptions.i]: {
    widget: WidgetWrapper(StacksWidget),
    widgetOptions: StacksWidgetOptions,
  },
};

// update version if you add or remove widgets to ignore persisted layout
const LAYOUT_VERSION = '2023-02-08';

const DEFAULT_LAYOUTS: Layouts = {
  xl: [
    { x: 0, y: 0, i: WelcomeWidgetOptions.i, ...WelcomeWidgetOptions.xl },
    { x: 0, y: 4, i: OnboardingWidgetOptions.i, ...OnboardingWidgetOptions.xl },
    { x: 6, y: 4, i: FavoritesWidgetOptions.i, ...FavoritesWidgetOptions.xl },
    { x: 0, y: 7, i: StacksWidgetOptions.i, ...StacksWidgetOptions.xl },
  ],
  lg: [
    { x: 0, y: 0, i: WelcomeWidgetOptions.i, ...WelcomeWidgetOptions.lg },
    { x: 0, y: 4, i: OnboardingWidgetOptions.i, ...OnboardingWidgetOptions.lg },
    { x: 6, y: 4, i: FavoritesWidgetOptions.i, ...FavoritesWidgetOptions.lg },
    { x: 0, y: 7, i: StacksWidgetOptions.i, ...StacksWidgetOptions.lg },
  ],
  md: [
    { x: 1, y: 0, i: WelcomeWidgetOptions.i, ...WelcomeWidgetOptions.md },
    { x: 1, y: 4, i: OnboardingWidgetOptions.i, ...OnboardingWidgetOptions.md },
    { x: 6, y: 4, i: FavoritesWidgetOptions.i, ...FavoritesWidgetOptions.md },
    { x: 1, y: 7, i: StacksWidgetOptions.i, ...StacksWidgetOptions.md },
  ],
  sm: [
    { x: 0, y: 0, i: WelcomeWidgetOptions.i, ...WelcomeWidgetOptions.sm },
    { x: 0, y: 4, i: OnboardingWidgetOptions.i, ...OnboardingWidgetOptions.sm },
    { x: 5, y: 7, i: FavoritesWidgetOptions.i, ...FavoritesWidgetOptions.sm },
    { x: 0, y: 7, i: StacksWidgetOptions.i, ...StacksWidgetOptions.sm },
  ],
  xs: [
    { x: 0, y: 0, i: WelcomeWidgetOptions.i, ...WelcomeWidgetOptions.xs },
    { x: 0, y: 4, i: OnboardingWidgetOptions.i, ...OnboardingWidgetOptions.xs },
    { x: 5, y: 20, i: FavoritesWidgetOptions.i, ...FavoritesWidgetOptions.xs },
    { x: 0, y: 20, i: StacksWidgetOptions.i, ...StacksWidgetOptions.xs },
  ],
};

const EVERY_WIDGET = Object.entries(widgets).map(([key, _value]) => key);

const DEFAULT_LAYOUT: LayoutInterface = {
  version: LAYOUT_VERSION,
  layouts: DEFAULT_LAYOUTS,
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  '@keyframes opacity': {
    '0%': { opacity: 1 },
    '50%': { opacity: .5 },
    '100%': { opacity: 1 },
  },
  editing: {
    animation: '$opacity 2s',
    animationIterationCount: 'infinite',
    '& > div:hover': {
      transition: 'box-shadow 0.5s',
      boxShadow: theme.shadows[10],
      cursor: 'pointer',
    },
  },
  notEditing: {
    '& .react-resizable-handle-se': {
      visibility: 'hidden',
    },
  },
  addingList: {
    display: 'flex',
  },
  listContainer: {
    display: 'flex',
    overflow: 'auto',
  },
  item: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediaLong: {
    height: theme.spacing(15),
    width: theme.spacing(50),
    objectFit: 'contain',
  },
  media: {
    height: theme.spacing(15),
    width: theme.spacing(25),
    objectFit: 'contain',
  },
},
));

const Dashboard = (): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const [editing, setEditing] = useState(false);

  const storedLayout: LayoutInterface = JSON.parse(
    localStorage.getItem(STORAGE_KEY_DASHBOARD_LAYOUT) ?? JSON.stringify(DEFAULT_LAYOUT),
  );
  const [activeLayout, setActiveLayout] =
    useState(storedLayout.version === LAYOUT_VERSION ? storedLayout : DEFAULT_LAYOUT);

  const [draggingElement, setDraggingElement] = useState<string>(OnboardingWidgetOptions.i);

  const [currentWidth, setCurrentWidth] = useState<string>('md');

  const currentLayoutWidgets =
    Object.entries(activeLayout.layouts).at(0)?.[1].map(widget => widget.i) as string[];

  const handleWidthChange = (width: number) => {
    const newWidth = Object.entries(theme.breakpoints.values)
      .reverse().filter(([_key, value]) => value < width).at(0)?.[0];
    if (currentWidth !== newWidth) {
      setCurrentWidth(newWidth as string);
    }
  };

  const storeLayout = useCallback((layouts: Layouts) => {
    localStorage.setItem(
      STORAGE_KEY_DASHBOARD_LAYOUT,
      JSON.stringify({
        version: LAYOUT_VERSION, layouts,
      }));
  }, []);

  useEffect(() => {
    storeLayout(activeLayout.layouts);
  }, [activeLayout]);

  const droppingItemValues = (widgets[draggingElement]?.widgetOptions as WidgetOptions)
    [currentWidth as keyof WidgetOptions] as WidgetDimensions || { w: 1, h: 1 };
  const droppingItem = {
    i: draggingElement as string,
    w: droppingItemValues?.w as number,
    h: droppingItemValues?.h as number,
  };

  const removeWidget = (key: string) => {
    const newActiveLayout = Object.fromEntries(Object.entries(activeLayout.layouts)
      .map(([size, relativeLayout]) =>
        [size, relativeLayout.filter(widgetLayout => widgetLayout.i !== key)],
      ));
    setActiveLayout({
      version: LAYOUT_VERSION,
      layouts: newActiveLayout,
    });
  };

  const handleDragStart = (widgetKey: string) => {
    setDraggingElement(widgetKey);
  };

  const handleOnDrop = (layout: Layout[]) => {
    const newActiveLayout = Object.fromEntries(Object.entries(activeLayout.layouts)
      .map(([size, _]) => ([size, layout])));
    setActiveLayout({
      version: LAYOUT_VERSION,
      layouts: newActiveLayout,
    });
  };

  // Quick fix for the responsive grid layout failing
  // on the initial load it calculates the size wrong and thus extends
  // beyond the width of the viewport
  // This is only an issue on smaller viewports
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 50);

  return (
    <CustomerLayout
      title="Dashboard"
      pageName="Dashboard"
      actions={
        <Button
          size="small"
          onClick={() => setEditing(!editing)}
          startIcon={<SettingsIcon />}
        >
          {editing ? 'Save Changes' : 'Rearrange Widgets'}
        </Button>
      }
    >
      {editing &&
        <Accordion defaultExpanded={false}>
          <AccordionSummary expandIcon={<ExpandIcon />}>
            <Typography >Add more Widgets</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={classes.listContainer} mb={8}>
              <List className={classes.addingList}>
                {
                  EVERY_WIDGET.filter(key => !currentLayoutWidgets.includes(key)).map((item: string) =>
                    <ListItem
                      key={item}
                      className={classes.item}
                    >
                      <Card
                        draggable
                        onDragStart={() => handleDragStart(item)}
                      >
                        <CardHeader title={capitalize(singlify(item).replace('_', ' '))} />
                        <CardMedia
                          component="img"
                          className={classes[widgets[item]?.widgetOptions.style || 'media']}
                          image={widgets[item]?.widgetOptions.image as string}
                        />
                      </Card>
                    </ListItem>,
                  )
                }
              </List>
            </Box>
          </AccordionDetails>
        </Accordion>
      }
      <ResponsiveGridLayout
        className={editing ? classes.editing : classes.notEditing}
        layouts={activeLayout.layouts}
        onLayoutChange={(_, layouts) => storeLayout(layouts)}
        cols={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
        onWidthChange={(containerWidth) => handleWidthChange(containerWidth)}
        rowHeight={32}
        breakpoints={theme.breakpoints.values}
        isDraggable={editing}
        isResizable={editing}
        isDroppable
        droppingItem={droppingItem}
        onDrop={(layout) => handleOnDrop(layout)}
      >
        {Object.entries(widgets).map(([key, value]) => {
          const { widget: Widget } = value;
          return currentLayoutWidgets.includes(key) &&
            <Widget key={key} removeCallback={() => removeWidget(key)} editing={editing} />;
        })};
      </ResponsiveGridLayout>
    </CustomerLayout>
  );
};

export default Dashboard;
