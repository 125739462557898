import { SubscriptionService, useApiGetter, useRoutes } from '@localstack/services';
import { Permission, PlanFamily, SubscriptionStatus } from '@localstack/types';
import { Button, styled } from '@mui/material';
import { ReactElement, useMemo } from 'react';

import { formatCountdownFromTimestamp } from '~/util/timeUtils';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks';

import { Banner } from './Banner';

const CustomAnchor = styled('a')(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: '600',
}));

const BannerContent = styled('div')({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const UpgradeFromTrialBanner = (): ReactElement => {
  const { can } = useAuthProvider();
  const { goto } = useRoutes();

  const { data: subscriptions } = useApiGetter(SubscriptionService, 'listSubscriptions', [], {
    suppressErrors: true,
    enable: can(Permission.READ_SUBSCRIPTIONS),
  });

  const showTrialBanner = useMemo(
    () =>
      subscriptions?.length === 1 &&
      subscriptions.some(
        (sub) =>
          sub.plan?.family === PlanFamily.TRIAL_PLANS &&
          [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING].includes(sub.status),
      ),
    [subscriptions],
  );

  const trialSubscription = subscriptions?.find((sub) => sub.status === SubscriptionStatus.TRIALING);

  const timeToTrialEnd = trialSubscription ? formatCountdownFromTimestamp(trialSubscription.cancel_at) : null;

  if (showTrialBanner) {
    return (
      <Banner
        content={
          <BannerContent>
            <span>
              You have <b>{timeToTrialEnd}</b> left in your trial. Need help onboarding?
              <CustomAnchor href="https://www.localstack.cloud/demo"> Talk to sales</CustomAnchor>
            </span>
            <Button variant="contained" color="info" onClick={() => goto(AppRoute.PRICING)}>
              Upgrade
            </Button>
          </BannerContent>
        }
      />
    );
  }

  return <></>;
};
