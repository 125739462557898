import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Grid, CardHeader, CardContent } from '@mui/material';
import { VALIDATION_RULES } from '@localstack/services';
import { ControlledTextField, PasswordCheck } from '@localstack/ui';

import { ApiError } from './ApiError';
import { AxiosErrorType } from './common-types';

type Props = {
  error: AxiosErrorType
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pwdConfirmCard: {
      [theme.breakpoints.up('sm')]: {
        order: 1,
      },
    },
    pwdCheckCard: {
      [theme.breakpoints.up('sm')]: {
        order: 2,
      },
    },
  }),
);

export const LoginDetails = ({ error }: Props): ReactElement => {
  const { control, watch } = useFormContext();
  const password = watch('password');
  const classes = useStyles();

  return (
    <>
      <CardHeader title="Sign up with email address" />
      <CardContent>
        <ApiError error={error} />
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="email"
                  fullWidth
                  required
                  label="Email address"
                  type="text"
                  variant="outlined"
                  rules={{
                    ...VALIDATION_RULES.email,
                    ...VALIDATION_RULES.required,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <ControlledTextField
                      control={control}
                      name="password"
                      fullWidth
                      required
                      label="Password"
                      type="password"
                      variant="outlined"
                      rules={VALIDATION_RULES.password}
                    />
                  </Grid>
                  {password && <Grid item xs={12} className={classes.pwdCheckCard}>
                    <PasswordCheck password={password ?? ''} />
                  </Grid>}
                  <Grid item xs={12} sm={6} className={classes.pwdConfirmCard}>
                    <ControlledTextField
                      control={control}
                      name="password_confirmation"
                      fullWidth
                      required
                      label="Confirm Password"
                      type="password"
                      variant="outlined"
                      rules={{
                        validate: (value) => (value === password) || 'Passwords do not match',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
