import { SERVICE_CODES } from '@localstack/constants';
import { capitalise } from '@localstack/services';
import { AggregatedPolicies } from '@localstack/types';
import { CodeSnippetViewer } from '@localstack/ui';
import { Close } from '@mui/icons-material';
import { Box, CardContent, CardHeader, Chip, Divider, Drawer, IconButton, Typography } from '@mui/material';

import { ReactElement } from 'react';

import { CustomAccordion } from '../CustomAccordion';
import { StatusChip } from '../StatusBadge';
import { useStyles } from '../common/style';

type PolicyStreamDrawerProps = {
  policy: Optional<AggregatedPolicies>;
  onClose: () => unknown;
}

export const PolicyStreamDrawer = ({ policy, onClose }: PolicyStreamDrawerProps): ReactElement => {
  const classes = useStyles();
  
  return (
    <Drawer 
      open={!!policy}
      onClose={onClose}
      PaperProps={{
        className: classes.drawer,
      }}
      anchor='right'
    >
      {!!policy && (
        <>
          <CardHeader 
            title={
              <Box className={classes.drawerHeader}>
                <Box className={classes.drawerSubHeader}>
                  <Typography variant="body1">
                    Operation
                  </Typography>
                  {policy.request.internal && (
                    <Chip label='Internal' variant='filled' size='small'/>
                  )}
                  <Box sx={{ flex: 1 }} />
                  <IconButton onClick={onClose}>
                    <Close/>
                  </IconButton>
                </Box>
                <Box className={classes.drawerSubHeader}>
                  <Typography variant='h5'>
                    {policy.request.operation}{' '}
                  </Typography>
                  <Chip 
                    variant='outlined' 
                    size='small'
                    label={SERVICE_CODES[policy.request.service as keyof typeof SERVICE_CODES]}
                  />
                  <Box sx={{ flex: 1 }} />
                  <StatusChip status={policy.status}/>
                </Box>
              </Box>
            }
          />
          <Divider/>
          <CardContent className={classes.cardContent}>
            {policy.policies.map((pol, idx) =>
              <CustomAccordion
                key={`${pol.resource}-${pol.policy_type}-${idx}`}
                expanded
                title={`Required ${capitalise(pol.policy_type)}-Based Policy`}
                details={<CodeSnippetViewer data={JSON.stringify(pol.policy_document)} />}
                copyContent={JSON.stringify(pol.policy_document)} 
              />,
            )}
            <Divider/>
            <CustomAccordion
              title='Request Params'
              expanded
              details={
                <CodeSnippetViewer data={JSON.stringify(policy.request.parameters)}/>
              }
            />
          </CardContent>
        </>
      )}
    </Drawer>
  );
};
