import { Subscription } from '@localstack/types';
import { ProgressButton, Dropdown } from '@localstack/ui';
import { Tooltip, MenuItem } from '@mui/material';
import { ReactElement } from 'react';

type LicenseSelectInfo = {
  displayName: string,
  subscription: Subscription,
  disabled: boolean,
}

type AssignLicenseButtonProps = {
  licenseInfo: Array<LicenseSelectInfo>,
  onSelect: (subscription: Subscription) => void,
}

export const AssignLicenseButton = ({ licenseInfo, onSelect }: AssignLicenseButtonProps): ReactElement => {
  const onClickHandler = (subscription: Subscription) =>
    async (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation();
      await onSelect(subscription);
    };

  // default case where there is 1 subscription
  if (licenseInfo.length === 1 && licenseInfo[0]) {
    const info = licenseInfo[0];

    return (
      <Tooltip
        title="All licenses already assigned."
        disableHoverListener={!info.disabled}
        placement='top'
      >
        <span>
          <ProgressButton
            variant='outlined'
            size='small'
            onClick={onClickHandler(info.subscription)}
            disabled={info.disabled}
          >
            Assign License
          </ProgressButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <Dropdown
      size='small'
      label="Assign License"
      stopPropagation
      disabled={licenseInfo.length === 0}
    >
      {licenseInfo.map((info, key) => (
        <Tooltip
          key={key}
          title="All licenses already assigned."
          disableHoverListener={!info.disabled}
          placement='top'
        >
          <span>
            <MenuItem
              onClick={onClickHandler(info.subscription)}
              key={info.subscription.id}
              disabled={info.disabled}
            >
              <span style={{ textTransform: 'capitalize' }}>{info.displayName}</span>
            </MenuItem>
          </span>
        </Tooltip>
      ))}
    </Dropdown>
  );
};
