import { ReactElement } from 'react';
import { Request } from 'aws-sdk';

import { SERVICE_NAME } from './serviceName';
import { EphemeralInstanceConfig, EphemeralInstance, Extension } from './generated';

// I hope at some point we will be generating these types from LocalStack models

export enum HealthState {
  INITIALIZED = 'initialized',
  AVAILABLE = 'available',
  RUNNING = 'running',
  ERROR = 'error',
  DISABLED = 'disabled',
}

export enum OFFLINE {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  OFFLINE = 'offline',
}
export const HealthStatePlus = {
  ...HealthState,
  ...OFFLINE,
};

export type HealthStatePlusType = OFFLINE | HealthState;

export interface Paginated<T> {
  items: T[];
  last_evaluated_key?: any; // eslint-disable-line
}

export type HealthServices = {
  [key in SERVICE_NAME]: HealthState
}

export interface Health {
  features: {
    [key: string]: HealthState;
  },
  edition: 'enterprise' | 'pro' | 'community',
  services: HealthServices,
  version: string;
}

// generic class to represent metamodel map objects
export interface CloudPodMetamodelData {
  [region: string]: unknown;
}

export type CloudPodMetadata = {
  name: string,
  size: string,
  services: string[],
  localstack_ext_version: string,
  moto_ext_version: string,
  pro: boolean,
}

export type CloudPodRemoteAttributes = {
  is_public: boolean,
  description?: string,
  services?: string[],
}

export const enum CloudPodMergeStrategy {
  OVERWRITE = 'overwrite',
  ACCOUNT_REGION_MERGE = 'account-region-merge',
  SERVICE_MERGE = 'service-merge',
}

export type CloudPodInjectPodBody = {
  data: Blob,
  params: {
    merge_strategy: CloudPodMergeStrategy,
    pod_name: string,
    pod_version: number,
  }
}

export interface AWSResourceSchema {
  documentation?: string;
  metadata?: Record<string, string>;
  operations?: unknown; // TODO: Add types for operations
  shapes: Record<string, ResolvedMagicSchema>;
}

export interface ResolvedMagicSchema {
  type: 'structure' |
  'string' |
  'boolean' |
  'double' |
  'long' |
  'integer' |
  'timestamp' |
  'listOfString' |
  'listOfStructure' |
  'mapOfString' |
  'mapOfBoolean' |
  'mapOfStructure' |
  string;
  name?: string;
  enum?: string[];
  documentation?: string | null | undefined;
  members?: ResolvedMagicSchema[];
  required?: boolean;
}

export type MagicFormExternalFields = {
  // eslint-disable-next-line
  [key: string]: (control: any, fieldName: string, required: boolean, formData?: { [x: string]: any; }) => ReactElement;
};

export type MagicFormInjectedFields = {
  // eslint-disable-next-line
  [key: string]: {
    position: 'before' | 'after',
    element: ReactElement
  } | undefined;
};

export type MagicDetailsExternalFields = {
  // eslint-disable-next-line
  [key: string]: (data: any, fieldName: string) => ReactElement;
}

export type MagicExternalFields = MagicFormExternalFields | MagicDetailsExternalFields;

export interface MagicFieldConditions {
  [key: string]: [string, '===' | '!==' | 'in', (string | string[] | boolean)] | boolean;
}

export type MagicExpandableRows = {
  parentId?: string;
  level?: number;
  childrenIds?: string[];
}

/**
 * Messages are accessed using the backdoor localstack api
 */
export type BackdoorSESMessage = {
  Id: string,
  Timestamp: string,
  Region: string,
  Source: string,
  'Destination.ToAddresses': string[],
  'Destination.CcAddresses': string[],
  'Destination.BccAddresses': string[],
  Subject: string,
  'Body.text_part': string,
  'Body.html_part': string
  RawData?: string,
}

export type WidgetDimensions = {
  w: number,
  h: number
}
export type WidgetOptions = {
  i: string,
  image: string,
  style: 'media' | 'mediaLong',
  xl: WidgetDimensions,
  lg: WidgetDimensions,
  md: WidgetDimensions,
  sm: WidgetDimensions,
  xs: WidgetDimensions,
}
export interface Layout {
  children?: any; // eslint-disable-line
  title?: any; // eslint-disable-line
  documentTitle?: string;
  actions?: any; // eslint-disable-line
  tabs?: any; // eslint-disable-line
  maxWidth?: any; // eslint-disable-line
}

export type ExtractMethodsRecord<T> =
  // eslint-disable-next-line
  Pick<T, { [K in keyof T]: T[K] extends (...args: any) => any ? K : never }[keyof T]>;

/** Extract response type from AWS SDK method */
// eslint-disable-next-line
export type ExtractResponse<T> = T extends Request<infer R, any> ? R : (T extends Promise<infer R> ? R : never)

/** Return the type of the elements in an array or tuple */
export type ArrayElement<ArrayType> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

/**
 * Extract params from overloaded functions
 * see https://github.com/microsoft/TypeScript/issues/32164 (stolen from there)
 */
export type ExtractOverloadedParams<T> = T extends {
  (...args: infer A1): unknown;
  (...args: infer A2): unknown;
  (...args: infer A3): unknown;
  (...args: infer A4): unknown;
  (...args: infer A5): unknown;
  (...args: infer A6): unknown;
  (...args: infer A7): unknown;
  (...args: infer A8): unknown;
  (...args: infer A9): unknown;
}
  ? [A1, A2, A3, A4, A5, A6, A7, A8, A9]
  : never;

/** Filter our `unknowns` after applying `ExtractOverloadedParams` */
export type FilterUnknowns<T> = T extends [infer A, ...infer Rest]
  ? unknown[] extends A
  ? FilterUnknowns<Rest>
  : [A, ...FilterUnknowns<Rest>]
  : T;

/** Converts tuple of arguments into union of arrays */
export type TupleArrayUnion<A extends readonly unknown[][]> = A extends (infer T)[]
  ? T extends unknown[]
  ? T
  : []
  : [];


// at some point should go to BE
export interface Address {
  name?: string;
  country?: string;
  address?: string;
  phone?: string;
  zip?: string;
  city?: string;
  state?: string;
}

export enum CallbackOrigin {
  MARKETPLACE = 'marketplace',
}

export type LocalStackEvent = {
  event: string;
  message: string;
};

export enum LocalStackInstanceType {
  REGULAR_INSTANCE = 'regular_instance',
  EPHEMERAL_INSTANCE = 'ephemeral_instance',
}

export interface LocalStackInstance {
  id: string;
  name: string;
  endpoint: string;
  image?: 'localstack/localstack-pro' | 'localstack/snowflake';
  auto_load_pod?: string;
  instanceType: LocalStackInstanceType;
  description?: string | null | undefined;
  startedAt: number;
  podVersion?: number | null | undefined;
  session_id?: string,
  machine_id?: string,
  running?: boolean,
  ephemeralInstanceConfig?: EphemeralInstanceConfig,
  ephemeralInstance?: EphemeralInstance,
}

export type EnrichedExtension = Extension & {
  logoFile?: File,
  isDev?: boolean
}

export enum IAMPolicyEngineState {
  Disabled = 'DISABLED',
  EngineOnly = 'ENGINE_ONLY',
  SoftMode = 'SOFT_MODE',
  Enforced = 'ENFORCED',
}

export type IAMPolicyStreamConfigPayload = {
  state: IAMPolicyEngineState,
}

export type IAMPolicyStreamConfig = {
  state: IAMPolicyEngineState,
  config_enforce_iam: boolean,
  config_iam_soft_mode: boolean,
}

interface StatementPrincipal {
  AWS: string | string[];
  Federated: string | string[];
  Service: string | string[];
  CanonicalUser: string | string[];
}

interface Condition {
  [key: string]: { [key: string]: string };
}

interface Statement {
  Sid: string,
  Effect: string;
  Principal: string | StatementPrincipal;
  NotPrincipal: string | StatementPrincipal;
  Action: string | string[];
  NotAction: string | string[];
  Resource: string | string[];
  NotResource: string | string[];
  Condition: Condition;
}

interface PolicyDocument {
  Version: string;
  Id: string;
  Statement: Statement[];
}

interface RequestData {
  service: string;
  operation: string;
  parameters: object;
  id: string;
  allowed?: string;
  internal?: boolean,
}

export interface GeneratedPolicy {
  resource: string;
  policy_document: PolicyDocument;
  policy_type: 'identity' | 'resource';
}

export type RawPolicyStreamResponse = GeneratedPolicy & {
  request: RequestData;
}

export interface PolicyStreamResponse {
  request: RequestData;
  policy: GeneratedPolicy;
}

export enum RequestStatus {
  SUCCESS = 'Success',
  WARNING = 'Warning',
  DENIED = 'Denied',
}

export interface AggregatedPolicies {
  request: RequestData;
  policies: GeneratedPolicy[];
  timestamp: string;
  status: RequestStatus;
}

export type LocalStackConfigUpdateParams = {
  'variable': string,
  'value': string | number,
}

export type CICreditRangesType = {
  'green': [number, number];
  'orange': [number, number];
  'red': [number, number];
  'extra': [number, number];
}

export type ForbiddenValuesType = Record<string, string | string[]>;

export const enum FeatureMaturityLevel {
  GA = 'general_availability',
  PREVIEW = 'preview',
  EXPERIMENTAL = 'experimental',
}

export type LocalStackChaosFaults = LocalStackChaosFault[]

export type LocalStackChaosFault = {
  region?: string;
  service?: string;
  operation?: string;
  probability?: number;
  description?: string;
  error?: LocalStackChaosFaultError;
}
type LocalStackChaosFaultError = {
  statusCode?: number;
  code?: string
}

export type LocalStackChaosNetworkEffect = {
  latency?: number;
}

export type LocalStackChaosExperiment = LocalStackChaosFaults | LocalStackChaosNetworkEffect;
