import React, { ReactElement } from 'react';
import { Link, LinkProps } from '@mui/material';

import { NavLinkProps, NavLink } from '../NavLink';

export type NewTabLinkProps = (NavLinkProps | LinkProps) & {
  type: 'NavLink' | 'Link';
};

// eslint-disable-next-line react/display-name
export const NewTabLink = React.forwardRef<HTMLAnchorElement, NewTabLinkProps>(({
  children,
  type,
  ...rest }: NewTabLinkProps, ref): ReactElement => {
  const isUsingElectron = navigator.userAgent.toLowerCase().includes('electron');
  const newTarget = isUsingElectron ? undefined : '_blank';

  if (type === 'Link') {
    return (
      <Link {...rest} target={newTarget} ref={ref} underline="hover">
        {children}
      </Link>
    );
  }

  const navLinkProps = rest as NavLinkProps;
  return (
    <NavLink
      {...navLinkProps}
      target={newTarget}
      ref={ref}
    >
      {children}
    </NavLink>
  );
});
