import { useEffect, useCallback, ReactElement } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Typography, Link } from '@mui/material';
import { CallbackOrigin } from '@localstack/types';
import { AuthService, useSnackbar, useApiEffect } from '@localstack/services';
import { NavLink } from '@localstack/ui';

import { ExternalLink } from '@localstack/constants';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks';
import { updatePreSignInPageURL } from '~/util/storage';

import { BaseActivation } from './components/BaseActivation';

const ERROR_MESSAGE = 'Subscription failed';


export const MarketplaceActivation = (): ReactElement => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { userInfo, reloadUserInfo } = useAuthProvider();
  const { pathname, search } = useLocation();
  const { token } = useParams<'token'>() as { token: string };

  const isAuthenticated = userInfo?.roles.includes('authenticated');

  const { exchangeMarketplaceToken, isLoading } = useApiEffect(AuthService, ['exchangeMarketplaceToken']);

  const subscribe = useCallback(async () => {
    try {
      await exchangeMarketplaceToken({ token });
      await reloadUserInfo(); // pick up new adjusted permissions
      navigate(`${AppRoute.WORKSPACE_SUBSCRIPTIONS}?origin=${CallbackOrigin.MARKETPLACE}`);
    } catch (_e) {
      showSnackbar({ severity: 'error', message: ERROR_MESSAGE });
    }
  }, [token]);

  useEffect(() => {
    // remember this page, s.t. user is redirected here after sign in
    updatePreSignInPageURL(`${pathname}${search}`);
  }, []);

  return (
    <BaseActivation
      message={
        <>
          {isAuthenticated ? (
            <>
              <Typography paragraph>
                Click Subscribe button to complete the subscription.<br />
              </Typography>
              <Typography paragraph>
                Once subscribed, any payments associated with the subscription will become part of your AWS bills.
                Subscription management (incl. updates, downgrades and cancellation){' '}
                will only be possible within the AWS Marketplace.
              </Typography>
              <Typography paragraph variant="caption">
                By subscribing you agree to the{' '}
                <Link
                  href={ExternalLink.LEGAL_PRIVACY_POLICY}
                  target="__blank"
                  underline="hover"
                >Privacy Policy</Link> and
                the{' '}
                <Link href={ExternalLink.LEGAL_TOS} target="__blank" underline="hover">Terms and Conditions</Link>{' '}
                of this service.
              </Typography>
            </>
          ) : (
            <Typography>
              Please <NavLink to={AppRoute.SIGN_IN}>sign in</NavLink>{' '}
              to your account to continue with you AWS Marketplace Subscription.
            </Typography>
          )}
        </>
      }
      activationDisabled={!isAuthenticated || isLoading}
      activationText="Subscribe"
      onActivation={subscribe}
    />
  );
};
