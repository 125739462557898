import { capitalise } from '@localstack/services';
import { CodeSnippetViewer } from '@localstack/ui';
import { Close, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Box, CardContent, CardHeader, Chip, Divider, Drawer, IconButton, Typography } from '@mui/material';

import { ReactElement, useState } from 'react';

import { SERVICE_CODES } from '~/constants';

import { CustomAccordion } from '../CustomAccordion';
import { GeneratedPolicy } from '../../../../../../../packages/types/src/localstack';
import { useStyles } from '../common/style';
import { getActionsForPolicy, getServicesForPolicy, renderActions, renderServices } from '../common/util';

type PolicySummaryDrawerProps = {
  policy: Optional<GeneratedPolicy>;
  onClose: () => unknown;
}

export const PolicySummaryDrawer = ({ policy, onClose }: PolicySummaryDrawerProps): ReactElement => {
  const classes = useStyles();

  const services = policy ? getServicesForPolicy(policy) : [];
  const actions = policy ? getActionsForPolicy(policy) : [];

  const [showAllActions, setShowAllActions] = useState(false);
  const [showAllServices, setShowAllServices] = useState(false);

  return (
    <Drawer 
      open={!!policy}
      onClose={onClose}
      PaperProps={{
        className: classes.drawer,
      }}
      anchor='right'
    >
      {!!policy && (
        <>
          <CardHeader 
            title={
              <Box className={classes.drawerHeader}>
                <Box className={classes.drawerSubHeader}>
                  <Typography variant="body1">
                    ARN
                  </Typography>
                  <Box sx={{ flex: 1 }} />
                  <IconButton onClick={onClose}>
                    <Close/>
                  </IconButton>
                </Box>
                <Box className={classes.drawerSubHeader}>
                  <Typography variant='h5'>
                    {policy.resource}
                  </Typography>
                </Box>
              </Box>
            }
          />
          <Divider/>
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>
              Services
              {services.length > 2 && (
                <IconButton onClick={() => setShowAllServices(!showAllServices)}>
                  {showAllServices ? <KeyboardArrowDown/> : <KeyboardArrowRight/> }
                </IconButton>
              )}
            </Typography>
            <Box sx={{ display: 'flex', gap: '0.4rem', flexWrap: 'wrap' }}>
              {showAllServices ?  
                services.map((service) => 
                  <Chip
                    key={service}
                    label={SERVICE_CODES[service as keyof typeof SERVICE_CODES]} 
                    variant='outlined'
                    size='small'
                  />,
                ) :
                renderServices(services)
              }
            </Box>
          </CardContent>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', paddingTop: 0 }}>
            <Typography>
              Actions
              {actions.length > 2 && (
                <IconButton onClick={() => setShowAllActions(!showAllActions)}>
                  {showAllActions ? <KeyboardArrowDown/> : <KeyboardArrowRight/> }
                </IconButton>
              )}
            </Typography>
            <Typography variant='body1'>
              {showAllActions ? actions.join(', ')
                :
                renderActions(actions)
              }
            </Typography>
          </CardContent>
          <Divider/>
          <CardContent className={classes.cardContent}>
            <CustomAccordion 
              title={`Required ${capitalise(policy.policy_type)}-Based Policy`}
              details={<CodeSnippetViewer data={JSON.stringify(policy.policy_document)} />} 
              copyContent={JSON.stringify(policy.policy_document)}
              expanded
            />
          </CardContent>
        </>
      )}
    </Drawer>
  );
};
