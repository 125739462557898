import { useMemo, useCallback, ReactElement, ChangeEvent, useEffect, useState } from 'react';
import { MembershipState, OrganizationMember, MembershipRole, User, Permission } from '@localstack/types';
import { PERMISSION_GROUPS, PERMISSION_TYPES, ROLE_MAPPINGS } from '@localstack/constants';
import { VALIDATION_RULES } from '@localstack/services';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useForm } from 'react-hook-form';

import {
  ProgressButton,
  ControlledCheckbox,
  ControlledAutocomplete,
  ControlledSelect,
  ControlledTextField,
} from '@localstack/ui';

import {
  Box,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  FormGroup,
  FormLabel,
} from '@mui/material';

// "escape" dots in role names
const encodeRole = (name: string) => name.replace('.', '--');
const decodeRole = (name: string) => name.replace('--', '.');

type Props = {
  className?: string;
  member?: Optional<OrganizationMember>;
  loading: boolean,
  searching: boolean;
  searchedUsers: [string, string][];
  onGetUser: (userId: string) => Promise<User>;
  onSearchUsers: (query: string) => unknown;
  onCreateMember: (membership: OrganizationMember) => unknown;
  onUpdateMember: (membership: OrganizationMember) => unknown;
}

export const MemberForm = ({
  className,
  member,
  loading,
  searching,
  searchedUsers,
  onGetUser,
  onSearchUsers,
  onCreateMember,
  onUpdateMember,
}: Props): ReactElement => {
  const { control, watch, setValue, handleSubmit, formState } = useForm<OrganizationMember>({
    defaultValues: { roles: [], permissions: [], ...member },
    mode: 'all',
  });

  const userId = watch('id');
  const roles = watch('roles') ?? [];

  const [user, setUser] = useState<Optional<User>>(null);

  const defaultPermissions = useMemo(() => Array.from(
    new Set(roles.reduce(
      (memo, role) => ([...memo, ...ROLE_MAPPINGS[role as keyof typeof ROLE_MAPPINGS]]),
      [] as Permission[],
    )),
  ), [roles]);

  const submitHandler = useCallback((data: OrganizationMember) => {
    if (!formState.isValid) return;

    const permissions = Object.entries(data.permissions ?? []).filter(
      ([, value]) => !!value).map(([key]) => decodeRole(key) as Permission,
    );

    if (!member && user) {
      const membership: OrganizationMember = {
        id: data.id,
        name: user.name ?? user.email,
        email: user.email,
        state: data.state,
        roles: data.roles,
        permissions,
      };

      return onCreateMember(membership);
    }

    if (member) {
      onUpdateMember({ ...data, permissions });
    }

    return;
  }, [member, user, formState.isValid, onCreateMember, onUpdateMember]);

  useEffect(() => {
    if (!member) return;

    Object.entries(member).forEach(
      ([key, value]) => setValue(key as keyof OrganizationMember, value, { shouldValidate: true }),
    );

    // a special handler for permissions
    if (!member.permissions) return;

    // eslint-disable-next-line
    setValue('permissions', {} as any); // reset permissions. as we have a custonm format

    member.permissions.forEach(
      (permission) => setValue(
        `permissions.${encodeRole(permission)}` as keyof OrganizationMember,
        true as any, // eslint-disable-line
        { shouldValidate: true },
      ),
    );
  }, [member]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    const loadUser = async () => {
      const newUser = await onGetUser(userId);
      setUser(newUser);
    };

    loadUser();
  }, [userId]);

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Card className={className} variant="outlined">
        <CardHeader title={member ? 'Edit Member' : 'Add new Member'} />
        <CardContent>
          {!member && (
            <Box mb={3}>
              <ControlledAutocomplete
                name="id"
                control={control}
                defaultValue=""
                rules={VALIDATION_RULES.required}
                options={searchedUsers}
                getOptionValue={(u: [string, string]) => u[0]}
                getValueOption={(u: [string, string]) => u[0]}
                getOptionLabel={(u: [string, string]) => u[1]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Member"
                    variant="outlined"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchUsers(e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {searching ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          )}
          <Box mb={3}>
            <ControlledSelect
              name="roles"
              control={control}
              variant="outlined"
              fullWidth
              multiple
              required
              rules={VALIDATION_RULES.required}
              label="Member Roles"
              options={[
                <MenuItem key={MembershipRole.ADMIN} value={MembershipRole.ADMIN}>Admin</MenuItem>,
                <MenuItem key={MembershipRole.MEMBER} value={MembershipRole.MEMBER}>Member</MenuItem>,
              ]}
            />
          </Box>
          <Box mb={3}>
            <ControlledSelect
              name="state"
              control={control}
              variant="outlined"
              fullWidth
              label="Membership State"
              required
              rules={VALIDATION_RULES.required}
              options={[
                <MenuItem key={MembershipState.INVITED} value={MembershipState.INVITED}>Invited</MenuItem>,
                <MenuItem key={MembershipState.ACTIVE} value={MembershipState.ACTIVE}>Active</MenuItem>,
                <MenuItem key={MembershipState.REMOVED} value={MembershipState.REMOVED}>Removed</MenuItem>,
              ]}
            />
          </Box>
          {member && (
            <>
              <Box mb={3}>
                <ControlledTextField
                  name="email"
                  control={control}
                  variant="outlined"
                  fullWidth
                  label="Email"
                  required
                  rules={{ ...VALIDATION_RULES.required, ...VALIDATION_RULES.email }}
                />
              </Box>
              <Box mb={3}>
                <ControlledTextField
                  name="name"
                  control={control}
                  variant="outlined"
                  fullWidth
                  label="Name"
                  required
                  rules={VALIDATION_RULES.required}
                />
              </Box>
            </>
          )}
          <Box>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Advanced Role Settings</Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <Box display="flex" flexWrap="wrap">
                  {Object.entries(PERMISSION_GROUPS).map(([title, perms]) => (
                    <Box mr={2} mt={2} key={title}>
                      <FormGroup>
                        <FormLabel component="legend">{title}</FormLabel>
                        {perms.map((permission) => (
                          <div key={permission}>
                            <ControlledCheckbox
                              // permissions may contain dots that are treated as nested objects
                              name={`permissions.${encodeRole(permission)}`}
                              control={control}
                              color="primary"
                              label={PERMISSION_TYPES[permission as keyof typeof PERMISSION_TYPES]}
                              disabled={defaultPermissions.includes(permission) || false}
                            />
                          </div>
                        ))}
                      </FormGroup>
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="contained"
            size="small"
            type="submit"
            loading={loading}
            disabled={(!member && !user) || !formState.isValid || searching}
          >
            Save
          </ProgressButton>
        </CardActions>
      </Card>
    </form>
  );
};
