import {
  getSelectedInstance,
  satisfiesVersionConstraint,
  useAwsEffect,
  useLocalstackStatus,
  useRoutes,
  useSnackbar,
} from '@localstack/services';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { Grid, CircularProgress, List, Typography, Alert, Button, Box } from '@mui/material';

import { makeStyles, createStyles } from '@mui/styles';

import { FeatureMaturityLevel, ServiceResourceAggregationObj } from '@localstack/types';

import { TableRows as TableRowsIcon, Add as AddIcon } from '@mui/icons-material';

import { CustomerLayout } from '~/layouts';

import { mergeDictWithListValues } from '~/util/objects';

import { AppRoute } from '~/config';

import { ServiceListEntry } from './ServiceListEntry';

const MIN_REQUIRED_VERSION = '4.0.0';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      flexDirection: 'column',
    },
  }),
);


export const StackOverview = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ServiceResourceAggregationObj>({});
  const { showSnackbar } = useSnackbar();

  const classes = useStyles();

  const instance = getSelectedInstance();
  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};

  const { running: isLocalStackRunning, version, isPro } = useLocalstackStatus(clientOverrides);
  const hasMinVersion = satisfiesVersionConstraint(version || '0', MIN_REQUIRED_VERSION);

  const canUseFeature = isLocalStackRunning && hasMinVersion && isPro;
  const { goto } = useRoutes();

  const { openResourcesStream } =
    useAwsEffect(
      'LocalStack',
      ['openResourcesStream'],
      { silentErrors: true, clientOverrides },
    );

  const adaptStreamData = useCallback((resources: ServiceResourceAggregationObj) => {
    const parsed_new_values = Object.entries(resources).reduce((acc, [serviceName, resourceList]) => {
      const parts = serviceName.split('::');
      const service = parts.at(1) ?? '';
      const resource = parts.at(2) ?? '';

      acc[service] = resourceList.map(res => ({ ...res, resource_type: resource }));
      return acc;
    }, {} as ServiceResourceAggregationObj);

    setData((prev) => (mergeDictWithListValues(prev, parsed_new_values)));
  }, [setData]);

  const readerRef = useRef<Optional<ReadableStreamDefaultReader<Uint8Array>>>();
  useEffect(() => {
    const openStreams = async () => {
      setIsLoading(true);

      const readerStream = await openResourcesStream({
        onChunkReceived: adaptStreamData,
        onError: () => showSnackbar({ message: 'An error occurred while fetching resources', severity: 'error' }),
        onStreamClose: () => setIsLoading(false),
      });

      readerRef.current = readerStream;
    };

    if (canUseFeature) {
      readerRef.current?.cancel?.();
      openStreams();
    }

    return () => {
      readerRef.current?.cancel?.();
    };
  }, [canUseFeature]);

  return (
    <CustomerLayout
      title='Stack Overview'
      planFamily={FeatureMaturityLevel.PREVIEW}
      actions={
        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">

          {isLoading &&
            <>
              <Grid item>
                <Typography variant='body2' color='textSecondary'>
                  Fetching resources...
                </Typography>
              </Grid>
              <Grid item>
                <CircularProgress size={18} color='secondary' />
              </Grid>
            </>
          }
          <Grid item>
            <Button
              variant='text'
              color='primary'
              disabled={!isPro}
              startIcon={<AddIcon />}
              onClick={() => goto(AppRoute.RESOURCES_OVERVIEW)}
            >
              Add Resources
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        {isLocalStackRunning && !hasMinVersion && (
          <Grid item xs={12}>
            <Alert severity='warning'>
              This feature requires LocalStack v4.0 and above.
            </Alert>
          </Grid>
        )}
        {isLocalStackRunning && !isPro && (
          <Grid item xs={12}>
            <Alert severity='warning'>
              This feature requires a paid tier
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <List disablePadding>
            {Object.entries(data).map(([service, resources], key) =>
              <ServiceListEntry key={key} serviceResAggregation={{ service, resources }} />,
            )}
          </List>
        </Grid>
      </Grid>
      {Object.entries(data).length === 0 &&
        <Box className={classes.container} gap={1}>
          <TableRowsIcon style={{ width: 64, height: 64, opacity: 0.3 }} />
          <Typography variant='h3' sx={{ opacity: 0.8 }}>
            No resources
          </Typography>
          <Typography variant='body1' sx={{ opacity: 0.6 }} textAlign='center'>
            Identify resources and locate the across regions <br />
            and accounts in a glance.
          </Typography>
        </Box>
      }
    </CustomerLayout>
  );
};
