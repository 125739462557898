import { SERVICE_NAME } from '@localstack/types';

import transcribe from './transcribe.svg';
import rds from './rds.svg';
import route53resolver from './route53resolver.svg';
import kinesisanalyticsv2 from './kinesisanalyticsv2.svg';
import scheduler from './scheduler.svg';
import backup from './backup.svg';
import mwaa from './mwaa.svg';
import kinesisanalytics from './kinesisanalytics.svg';
import glacier from './glacier.svg';
import secretsmanager from './secretsmanager.svg';
import stepfunctions from './stepfunctions.svg';
import iam from './iam.svg';
import redshift from './redshift.svg';
import cognitoidp from './cognito-idp.svg';
import glue from './glue.svg';
import lakeformation from './lakeformation.svg';
import qldb from './qldb.svg';
import elasticsearch from './elasticsearch.svg';
import sesv2 from './sesv2.svg';
import athena from './athena.svg';
import batch from './batch.svg';
import amplify from './amplify.svg';
import apigatewaymanagementapi from './apigatewaymanagementapi.svg';
import s3 from './s3.svg';
import s3control from './s3control.svg';
import timestreamwrite from './timestream-write.svg';
import fis from './fis.svg';
import logs from './logs.svg';
import infrastructure from './infrastructure.svg';
import sagemaker from './sagemaker.svg';
import lambda from './lambda.svg';
import autoscaling from './autoscaling.svg';
import mediastoredata from './mediastore-data.svg';
import mediastore from './mediastore.svg';
import xray from './xray.svg';
import firehose from './firehose.svg';
import docdb from './docdb.svg';
import redshiftdata from './redshift-data.svg';
import emr from './emr.svg';
import neptune from './neptune.svg';
import cloudtrail from './cloudtrail.svg';
import kafka from './kafka.svg';
import elb from './elb.svg';
import qldbsession from './qldb-session.svg';
import apigatewayv2 from './apigatewayv2.svg';
import transfer from './transfer.svg';
import resourcegroups from './resource-groups.svg';
import kms from './kms.svg';
import codecommit from './codecommit.svg';
import ses from './ses.svg';
import ecr from './ecr.svg';
import servicediscovery from './servicediscovery.svg';
import applicationautoscaling from './application-autoscaling.svg';
import ce from './ce.svg';
import rdsdata from './rds-data.svg';
import kinesis from './kinesis.svg';
import iotdata from './iot-data.svg';
import cloudwatch from './cloudwatch.svg';
import events from './events.svg';
import apigateway from './apigateway.svg';
import organizations from './organizations.svg';
import iot from './iot.svg';
import acm from './acm.svg';
import ec2 from './ec2.svg';
import ssm from './ssm.svg';
import dynamodbstreams from './dynamodbstreams.svg';
import elasticache from './elasticache.svg';
import ssoadmin from './sso-admin.svg';
import iotwireless from './iotwireless.svg';
import elasticbeanstalk from './elasticbeanstalk.svg';
import resourcegroupstaggingapi from './resourcegroupstaggingapi.svg';
import cognitoidentity from './cognito-identity.svg';
import efs from './efs.svg';
import timestreamquery from './timestream-query.svg';
import sagemakerruntime from './sagemaker-runtime.svg';
import sns from './sns.svg';
import serverlessrepo from './serverlessrepo.svg';
import emrserverless from './emr-serverless.svg';
import eks from './eks.svg';
import support from './support.svg';
import route53 from './route53.svg';
import elbv2 from './elbv2.svg';
import mq from './mq.svg';
import iotanalytics from './iotanalytics.svg';
import opensearch from './opensearch.svg';
import sts from './sts.svg';
import cloudfront from './cloudfront.svg';
import cloudformation from './cloudformation.svg';
import dynamodb from './dynamodb.svg';
import swf from './swf.svg';
import config from './config.svg';
import ecs from './ecs.svg';
import appconfig from './appconfig.svg';
import appsync from './appsync.svg';
import sqs from './sqs.svg';
import ram from './ram.svg';
import account from './account.svg';
import identitystore from './identitystore.svg';
import acmPca from './acm-pca.svg';
import managedblockchain from './managedblockchain.svg';
import wafv2 from './wafv2.svg';
import memorydb from './memorydb.svg';
import pipes from './pipes.svg';
import pinpoint from './pinpoint.svg';
import elastictranscoder from './elastictranscoder.svg';
import textract from './textract.svg';
import dms from './dms.svg';
import cloudcontrol from './cloudcontrol.svg';
import mediaconvert from './mediaconvert.svg';
import bedrock from './bedrock.svg';
import codedeploy from './codedeploy.svg';
import codepipeline from './codepipeline.svg';
import codebuild from './codebuild.svg';

type IconsMap = Partial<Record<SERVICE_NAME, typeof s3>>;

export const ICONS_MAP: IconsMap = {
  'transcribe': transcribe,
  'rds': rds,
  'route53resolver': route53resolver,
  'kinesisanalyticsv2': kinesisanalyticsv2,
  'scheduler': scheduler,
  'backup': backup,
  'mwaa': mwaa,
  'kinesisanalytics': kinesisanalytics,
  'glacier': glacier,
  'secretsmanager': secretsmanager,
  'stepfunctions': stepfunctions,
  'iam': iam,
  'redshift': redshift,
  'cognito-idp': cognitoidp,
  'glue': glue,
  'lakeformation': lakeformation,
  'qldb': qldb,
  'es': elasticsearch,
  'sesv2': sesv2,
  'athena': athena,
  'batch': batch,
  'amplify': amplify,
  'apigatewaymanagementapi': apigatewaymanagementapi,
  'kinesis': kinesis,
  's3': s3,
  's3control': s3control,
  'timestream-write': timestreamwrite,
  'fis': fis,
  'logs': logs,
  'infrastructure': infrastructure,
  'sagemaker': sagemaker,
  'lambda': lambda,
  'autoscaling': autoscaling,
  'mediastore-data': mediastoredata,
  'mediastore': mediastore,
  'xray': xray,
  'firehose': firehose,
  'docdb': docdb,
  'redshift-data': redshiftdata,
  'emr': emr,
  'neptune': neptune,
  'cloudtrail': cloudtrail,
  'kafka': kafka,
  'elb': elb,
  'qldb-session': qldbsession,
  'apigatewayv2': apigatewayv2,
  'transfer': transfer,
  'resource-groups': resourcegroups,
  'kms': kms,
  'codecommit': codecommit,
  'ses': ses,
  'ecr': ecr,
  'servicediscovery': servicediscovery,
  'application-autoscaling': applicationautoscaling,
  'ce': ce,
  'rds-data': rdsdata,
  'iot-data': iotdata,
  'cloudwatch': cloudwatch,
  'events': events,
  'apigateway': apigateway,
  'organizations': organizations,
  'iot': iot,
  'acm': acm,
  'ec2': ec2,
  'ssm': ssm,
  'dynamodbstreams': dynamodbstreams,
  'elasticache': elasticache,
  'sso-admin': ssoadmin,
  'iotwireless': iotwireless,
  'elasticbeanstalk': elasticbeanstalk,
  'resourcegroupstaggingapi': resourcegroupstaggingapi,
  'cognito-identity': cognitoidentity,
  'efs': efs,
  'timestream-query': timestreamquery,
  'sagemaker-runtime': sagemakerruntime,
  'sns': sns,
  'serverlessrepo': serverlessrepo,
  'emr-serverless': emrserverless,
  'eks': eks,
  'support': support,
  'route53': route53,
  'elbv2': elbv2,
  'mq': mq,
  'iotanalytics': iotanalytics,
  'opensearch': opensearch,
  'sts': sts,
  'cloudfront': cloudfront,
  'cloudformation': cloudformation,
  'dynamodb': dynamodb,
  'swf': swf,
  'config': config,
  'ecs': ecs,
  'appconfig': appconfig,
  'appconfigdata': appconfig,
  'appsync': appsync,
  'sqs': sqs,
  'ram': ram,
  'account': account,
  'identitystore': identitystore,
  'acm-pca': acmPca,
  'managedblockchain': managedblockchain,
  'wafv2': wafv2,
  'memorydb': memorydb,
  'pipes': pipes,
  'pinpoint': pinpoint,
  'elastictranscoder': elastictranscoder,
  'textract': textract,
  'dms': dms,
  'cloudcontrol': cloudcontrol,
  'mediaconvert': mediaconvert,
  'bedrock': bedrock,
  'bedrock-runtime': bedrock,
  'codepipeline': codepipeline,
  'codedeploy': codedeploy,
  'codebuild': codebuild,
};
