import axios from 'axios';
import useSWR from 'swr';
import { useState, ReactElement } from 'react';
import { MagicTable, ProgressButton, Breadcrumbs } from '@localstack/ui';
import { useEndpoint, AWS_SERVICE_PORTS, useAwsGetter, useSnackbar, useRoutes } from '@localstack/services';
import { ExternalLink } from '@localstack/constants';
import { Remove as ListIcon } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
  Box,
  Card,
  Link,
  CardContent,
  CardActions,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
} from '@mui/material';

import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

const REST_API_NAME = 'local-thundra-demo-localstack';
const POLLING_INTERVAL = 2_000;

interface DemoRequest {
  id: string;
  requestID: string;
  timestamp: string;
  status: 'QUEUED' | 'PROCESSING' | 'FINISHED';
}

const TABLE_SCHEMA = {
  shapes: {
    DemoRequest: {
      type: 'structure',
      members: {
        id: { type: 'string' },
        requestID: { type: 'string' },
        timestamp: { type: 'string' },
        status: { type: 'string' },
      },
    },
  },
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    marginBottom: theme.spacing(3),
  },
}));

export const DemoTwo = (): ReactElement => {
  const classes = useStyles();

  const { goto } = useRoutes();
  const [sending, setSending] = useState(false);

  const { endpoint } = useEndpoint();
  const { showSnackbar } = useSnackbar();

  const agwEndpoint = endpoint.includes(':') ? endpoint : `${endpoint}:${AWS_SERVICE_PORTS.APIGateway}`;

  const { data: apis } = useAwsGetter('APIGateway', 'getRestApis', [], { silentErrors: true });
  const restApi = apis?.items?.find((api) => api.name === REST_API_NAME);

  const restApiEndpoint = `${agwEndpoint}/restapis/${restApi?.id}/local/_user_request_`;

  const { data: requests, isValidating: isRequestsLoading } = useSWR(
    restApiEndpoint,
    async () => (await axios.get<DemoRequest[]>(`${restApiEndpoint}/requests`)).data,
    { refreshInterval: POLLING_INTERVAL },
  );

  const sendRequest = async () => {
    setSending(true);

    try {
      await axios.post(`${restApiEndpoint}/requests`, {});
      showSnackbar({
        message: 'New request has been sent to the API. It should appear in the list as QUEUED shortly.',
        severity: 'info',
      });
    } catch (_e) {
      showSnackbar({
        message: 'Unable to send request to the API. Please make sure that LocalStack is running properly.',
        severity: 'error',
      });
    } finally {
      setSending(false);
    }
  };

  return (
    <CustomerLayout
      documentTitle="Async Microservice with Tracing/Debugging"
      title={
        <Box>
          <Typography variant="h4">Async Microservice with Tracing/Debugging</Typography>
          <Breadcrumbs
            mappings={[
              ['Quickstart', () => goto(AppRoute.QUICKSTART)],
              ['Async Microservice with Tracing/Debugging', null],
            ]}
          />
        </Box>
      }
    >
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography>
                <Typography variant="h1" paragraph>
                  Async Microservice with Tracing/Debugging
                </Typography>
                <Typography paragraph>
                  This sample app illustrates a simple microservice
                  application with asynchronous request processing, all
                  running <b>locally inside LocalStack</b>. The app can be
                  configured to enable seamless tracing with{' '}
                  <strong><Link href={ExternalLink.EXTERNAL_THUNDRA} underline="hover">Thundra</Link></strong>{' '}
                  (by setting the <code>THUNDRA_APIKEY</code> config option in LocalStack).
                </Typography>
                <Typography paragraph>
                  The end-to-end process is illustrated in the figure on the right.
                </Typography>
                <Typography paragraph>Here&apos;s how the app works:</Typography>
                <List>
                  <ListItem>
                    <ListItemIcon><ListIcon /></ListItemIcon>
                    <ListItemText
                      primary={`
                        Use the button below to send a new request to local API
                        Gateway, which will then be handled by a Lambda function
                        for further processing.
                      `}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon><ListIcon /></ListItemIcon>
                    <ListItemText
                      primary={`
                        As the request is going through different stages of
                        processing, the table below will be updated with the
                        current request status (QUEUED -> PROCESSING -> FINISHED).
                      `}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon><ListIcon /></ListItemIcon>
                    <ListItemText
                      primary={`
                        Once the request is FINISHED, the processing result will
                        become available as a link to a file stored in S3.
                      `}
                    />
                  </ListItem>
                </List>
                <Typography paragraph>
                  <strong>Note:</strong> This app is designed to natively integrate with{' '}
                  <strong>Thundra Foresight</strong>, a tracing solution for debugging
                  your Lambdas and serverless applications. You can obtain an API key at{' '}
                  <Link href={ExternalLink.EXTERNAL_THUNDRA} underline="hover">{ExternalLink.EXTERNAL_THUNDRA}</Link>
                  {' '} and then configure the <code>THUNDRA_APIKEY</code> in the
                  LocalStack container environment. This will make the traces
                  and debug information automatically accessible in Thundra
                  after running this sample app.
                </Typography>
                <Typography paragraph>
                  The full source code of this sample is available in this
                  Github repo:
                  {' '}
                  <Link href={ExternalLink.DEMO_THUNDRA} underline="hover">
                    {ExternalLink.DEMO_THUNDRA}
                  </Link>
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <img alt="" src="/demo1/architecture.png" width="100%" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        {isRequestsLoading && <LinearProgress />}
        <MagicTable
          schema={TABLE_SCHEMA}
          entry="DemoRequest"
          rows={requests ?? []}
          idAttribute="id"
        />
        <CardActions>
          <ProgressButton
            color="primary"
            variant="contained"
            loading={sending}
            onClick={sendRequest}
          >
            Send Sample Request
          </ProgressButton>
        </CardActions>
      </Card>
    </CustomerLayout>
  );
};
