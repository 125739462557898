import { ReactNode } from 'react';
import { Path, UseFormReturn, FieldValues } from 'react-hook-form';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export interface RemoveButtonProps {
  removeHandler: () => void;
}

export const RemoveButton: React.FC<RemoveButtonProps> = ({
  removeHandler,
}) => (
  <IconButton
    onClick={removeHandler}
    aria-label="remove attribute"
    style={{ marginTop: '0.5em' }}
    size="large"
  >
    <Close />
  </IconButton>
);

export type ControlledFieldArrayType = <T extends FieldValues>(props: {
  name: Path<T>;
  methods: UseFormReturn<T>;
  renderField: (
    name: string,
    index: number,
    remove: (index: number) => void
  ) => ReactNode;
}) => JSX.Element;

/**
 * A component for displaying dynamic form fields.
 */
export const ControlledFieldArray: ControlledFieldArrayType = ({
  name,
  methods,
  renderField,
}) => {
  const { watch, setValue } = methods;
  const fields = (watch(name) || []) as unknown[];
  const remove = (index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue(name, fields.filter((_value: unknown, i: number) => i !== index) as any);
  };

  return (
    <>
      {fields.map((_value, index) => renderField(`${name}.${index}`, index, remove))}
    </>
  );
};
