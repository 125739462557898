import { IconButton, Alert, styled } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ReactElement } from 'react';

const BannerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: '8px',
});

const CustomBanner = styled(Alert)({
  maxWidth: '1280px',
  flexGrow: 1,
});

const BannerMessage = styled('div')(({ theme }) => ({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'space-between',
  fontSize: theme.typography.body1.fontSize,
}));

type BannerProps = {
  /** The text or element to appear within the banner */
  content: string | ReactElement;
  /** Callback for when the banner is closed, note if not present close button will not appear in banner  */
  onClose?: () => void;
};

export const Banner = ({ content, onClose }: BannerProps): ReactElement => (
  <BannerContainer>
    <CustomBanner severity="info">
      <BannerMessage>
        {content}
        {onClose && (
          <IconButton size="medium" onClick={onClose}>
            <Close />
          </IconButton>
        )}
      </BannerMessage>
    </CustomBanner>
  </BannerContainer>
);
