import { createTheme, Theme } from '@mui/material/styles';

import { STORAGE_KEY_THEME, ThemeType } from '../constants';

import lightPalette, { darkPalette } from './palette';
import createTypography from './typography';
import createOverrides from './overrides';

const theme = (themeType: string): Theme => createTheme(getThemeOptionsFromType(themeType));

const isPreferredDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

export const getThemeOptionsFromType = (themeType: string): object => {
  const palette = themeType === ThemeType.DARK ? darkPalette : lightPalette;
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette,
    typography: createTypography(palette),
    components: createOverrides(palette),
    zIndex: {
      tooltip: 1300,
      appBar: 1200,
      drawer: 1100,
    },
  };
};

export const getTheme = (themeType?: ThemeType): Theme => {
  if (themeType) {
    return theme(themeType);
  }

  const storageValue = getStoredThemeType();

  if (storageValue === ThemeType.SYSTEM) {
    return theme(isPreferredDark ? ThemeType.DARK : ThemeType.LIGHT);
  }
  return theme(storageValue as ThemeType);
};

export const getStoredThemeType = (): ThemeType =>
  localStorage.getItem(STORAGE_KEY_THEME) as ThemeType ?? ThemeType.LIGHT;
