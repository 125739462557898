import {
  OrganizationsService, UserService,
  VALIDATION_RULES, useApiEffect, useRoutes,
  useSnackbar,
} from '@localstack/services';
import {
  ControlledCheckbox, ControlledTextField, NewsletterSubscriptionLine, PasswordCheck,
  TOS_REQUIRED_ERROR, TosAgreementLine,
} from '@localstack/ui';
import { Box, CircularProgress, Grid, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { InvitationTokenPayload, TosAcceptanceContext } from '@localstack/types';

import { AppRoute } from '~/config';

import { BaseActivation } from './components/BaseActivation';

interface FormData {
  email: string;
  password: string;
  job_title: string;
  github_username: string;
  tos: boolean;
  newsletter: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pwdConfirmCard: {
      [theme.breakpoints.up('lg')]: {
        order: 1,
      },
    },
    pwdCheckCard: {
      [theme.breakpoints.up('lg')]: {
        order: 2,
      },
    },
  }),
);

const CONFIRMED_MESSAGE = 'Your membership has been confirmed, you can now sign in with your username and password';

const decodeAndParseInvitationData = (data: string): InvitationTokenPayload | undefined => {
  try {
    const decodedInvitationData = atob(data || '');
    const parsedInvitationData = JSON.parse(decodedInvitationData);
    return parsedInvitationData;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const MembershipActivation = (): ReactElement => {
  const { goto } = useRoutes();

  const [message, setMessage] = useState<JSX.Element | string>('');
  const classes = useStyles();

  const { org, key } = useParams<'org' | 'key'>();
  const { showSnackbar } = useSnackbar();

  const {
    handleSubmit,
    formState,
    control,
    watch,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      tos: false,
      newsletter: false,
    },
  });
  const password = watch('password');
  const processedInvitationData = decodeAndParseInvitationData(key || '');
  const isAccountCreation = processedInvitationData?.action === 'create';

  const { confirmMembership } = useApiEffect(
    OrganizationsService,
    ['confirmMembership'],
    { revalidate: ['listOrganizations'], revalidateOtherClient: { client: UserService, methods: ['getUser'] } },
  );

  const onSubmit = async (data: FormData) => {
    if (!org || !processedInvitationData?.invitation_token || (isAccountCreation && !password)) return;
    const { job_title, github_username, newsletter } = data;
    setMessage(<CircularProgress />);
    try {
      await confirmMembership(org, processedInvitationData.invitation_token, {
        password,
        job_title,
        github_username,
        newsletter,
        tos_acceptance_context: TosAcceptanceContext.MEMBERSHIP_CONFIRMATION,
      });
      showSnackbar({ severity: 'success', message: CONFIRMED_MESSAGE });
      goto(AppRoute.ACCOUNT);
    } catch (e) {
      const error = (e as AxiosError).response?.data.error || (e as Error).message;
      setMessage(<Typography color="error">{error}</Typography>);
    }
  };

  return (
    <BaseActivation
      message={
        <>
          <form>
            <Grid container spacing={2} textAlign='left'>
              <Grid item xs={12}>
                <Typography variant='h4' mb={1}>
                  {isAccountCreation ? 'Create Account and Join workspace' : 'Join workspace'}
                </Typography>
                {processedInvitationData &&
                  <Typography variant='body1' mb={2}>
                    {isAccountCreation
                      ?
                      <>
                        You have been invited to join the {' '}
                        workspace <b>{processedInvitationData?.workspace_name}</b>. <br />
                        Please enter your data below to create a new account and to join this workspace.
                      </>
                      :
                      <>
                        You have been invited to join the workspace {' '}
                        <b>{processedInvitationData?.workspace_name}</b> with your LocalStack account {' '}
                        <b>{processedInvitationData?.email}</b>
                      </>
                    }
                  </Typography>}
              </Grid>
              {isAccountCreation && (
                <>
                  {processedInvitationData?.email &&
                    <Grid item xs={12}>
                      <TextField
                        name="email"
                        fullWidth
                        required
                        label="Email address"
                        type="text"
                        variant="outlined"
                        disabled
                        value={processedInvitationData?.email}
                      />
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <ControlledTextField
                          control={control}
                          name="password"
                          fullWidth
                          required
                          label="Password"
                          type="password"
                          variant="outlined"
                          rules={VALIDATION_RULES.password}
                        />
                      </Grid>
                      {password && <Grid item xs={12} className={classes.pwdCheckCard}>
                        <PasswordCheck password={password ?? ''} />
                      </Grid>}
                      <Grid item xs={12} sm={6} className={classes.pwdConfirmCard}>
                        <ControlledTextField
                          control={control}
                          name="password_confirmation"
                          fullWidth
                          required
                          label="Confirm Password"
                          type="password"
                          variant="outlined"
                          rules={{
                            validate: (value) => (value === password) || 'Passwords do not match',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledTextField
                      control={control}
                      name="job_title"
                      fullWidth
                      label="Job Title"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledTextField
                      control={control}
                      name="github_username"
                      fullWidth
                      label="Github Username"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledCheckbox
                      label={
                        <Typography variant="body1" color="textSecondary" display="inline">
                          <TosAgreementLine />
                        </Typography>
                      }
                      name="tos"
                      control={control}
                      required
                      rules={{
                        required: TOS_REQUIRED_ERROR,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledCheckbox
                      label={
                        <NewsletterSubscriptionLine />
                      }
                      name="newsletter"
                      control={control}
                      rules={{}}
                    />
                  </Grid></>)
              }
              {message && <Grid item xs={12}>
                <Box mt={2}>
                  {message}
                </Box>
              </Grid>}
            </Grid>
          </form>
        </>
      }
      activationDisabled={!formState.isValid}
      activationText={isAccountCreation ? 'Create and Join' : 'Join'}
      onActivation={handleSubmit(onSubmit)}
    />
  );
};
