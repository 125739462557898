import axios from 'axios';
import { get } from 'lodash';
import React, { useState, useCallback, useEffect, useMemo, ReactElement } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Delete as DeleteIcon, Add as AddIcon, Check as CheckIcon, MoreHoriz as MoreIcon } from '@mui/icons-material';
import {
  MagicFormExternalFields, MagicFieldConditions, ResolvedMagicSchema,
  MagicFormInjectedFields, ForbiddenValuesType,
} from '@localstack/types';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
  Grid,
  Typography,
  Box,
  IconButton,
  MenuItem,
  TextField,
  Card,
  CardContent,
  Divider,
  CardHeader,
} from '@mui/material';

import {
  VALIDATION_RULES,
  transformMagicFieldName,
  resolveMagicType,
  singlify,
  removeEmptyProperties,
  getExternalMagicFieldRenderer,
  getFieldForbiddenValue,
  getInjectedFieldsRenderer,
  useBaseLayoutProvider,
  getMagicFieldVisibility,
} from '@localstack/services';

import { ControlledTextField, ControlledSelect, ControlledCheckbox, ControlledDropzone } from '../../../form';
import { ActionTitle } from '../../../display';
import { orderBy } from '../MagicTable/utils';

const DOCUMENTATION_EXPANSION_LIMIT = 500;

export interface MagicRenderer {
  tokens: (string | number)[];
  member: ResolvedMagicSchema;
  externalFields?: MagicFormExternalFields;
  injectedFields?: MagicFormInjectedFields;
  fieldConditions?: MagicFieldConditions;
  hideNames?: boolean;
  forbiddenValues?: ForbiddenValuesType
}

interface MemberTitleProps {
  member: ResolvedMagicSchema;
  hideName?: boolean;
}

const useMemberTitleStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '& ul': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const MemberTitle = ({ member, hideName }: MemberTitleProps) => {
  const classes = useMemberTitleStyles();

  const shouldTruncate = (member.documentation?.length ?? 0) > DOCUMENTATION_EXPANSION_LIMIT;
  const [showMore, setShowMore] = useState(!shouldTruncate);

  return (
    <div className={classes.root}>
      {!hideName && (
        <Typography variant='subtitle1'>{transformMagicFieldName(member.name)}</Typography>
      )}
      {shouldTruncate && !showMore && (
        <IconButton edge="start" onClick={() => setShowMore(!showMore)} size="large"><MoreIcon /></IconButton>
      )}
      {showMore && member.documentation && (
        <Typography variant="caption">
          {/* eslint-disable-next-line */}
          <span dangerouslySetInnerHTML={{ __html: member.documentation }} />
        </Typography>
      )}
    </div>
  );
};

const MapRenderer = ({ tokens, member, externalFields, injectedFields, fieldConditions, hideNames, forbiddenValues }:
  MagicRenderer) => {
  const [showNameInput, setShowNameInput] = useState(false);
  const [mappingName, setMappingName] = useState<string>('');

  const name = useMemo(() => [...tokens, member.name].join('.'), [tokens]);

  const { control, setValue, watch } = useFormContext();
  const formData = watch();

  const items = useMemo(() => get(formData, name, {}), [formData]);

  const handleAddItem = useCallback(() => {
    setValue(name, { ...items, [mappingName]: '' });
    setMappingName('');
    setShowNameInput(false);
  }, [mappingName, items]);

  const handleRemoveItem = useCallback(
    (key: string) => {
      const newItems = { ...items };
      delete newItems[key];
      setValue(name, newItems);
    }, [name, items],
  );

  const visibility = useMemo(
    () => getMagicFieldVisibility(name, fieldConditions, formData),
    [name, fieldConditions, formData],
  );

  const customRoot = useMemo(
    () => getExternalMagicFieldRenderer(name, externalFields),
    [name, externalFields],
  );

  const customMembers: { [key: string]: MagicFormExternalFields['*'] } = useMemo(
    () => Object.keys(items).reduce(
      (memo, key) => ({ ...memo, [key]: getExternalMagicFieldRenderer([name, key].join('.'), externalFields) }), {},
    ),
    [name, items, externalFields],
  );

  if (!visibility) return <></>;
  if (customRoot) return customRoot(control, name, !!member.required);

  return <>
    <ActionTitle
      title={<MemberTitle member={member} hideName={hideNames} />}
      actions={
        <IconButton onClick={() => setShowNameInput(!showNameInput)} size="large">
          <AddIcon />
        </IconButton>
      }
    />
    {showNameInput && (
      <Box mt={2} display="flex" alignItems="center">
        <Box flexGrow={1}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Mapping Name"
            required={!!member.required}
            value={mappingName || ''}
            onChange={(e) => setMappingName(e.target.value)}
          />
        </Box>
        <Box ml={1}>
          <IconButton onClick={handleAddItem} size="large">
            <CheckIcon />
          </IconButton>
        </Box>
      </Box>
    )}
    {Object.keys(items).map((key) => (
      <Box marginBottom={1} key={key} display="flex" alignItems="center">
        <Box width="30%">
          <Typography variant="subtitle2">{key} = </Typography>
        </Box>
        {member.type === 'mapOfString' && (
          <>
            <Box flexGrow={1} ml={1}>
              {customMembers[key] ? customMembers[key]?.(control, [name, key].join('.'), !!member.required) : (
                <PrimitiveRenderer
                  tokens={[...tokens, member.name as string]}
                  member={{ name: key, type: 'string' }}
                  fieldConditions={fieldConditions}
                  externalFields={externalFields}
                  injectedFields={injectedFields}
                  forbiddenValues={forbiddenValues}
                />
              )}
            </Box>
            <Box ml={1}>
              <IconButton onClick={() => handleRemoveItem(key)} size="large">
                <DeleteIcon />
              </IconButton>
            </Box>
          </>
        )}
        {member.type === 'mapOfBoolean' && (
          <>
            <Box flexGrow={1} ml={1}>
              {customMembers[key] ? customMembers[key]?.(control, [name, key].join('.'), !!member.required) : (
                <PrimitiveRenderer
                  tokens={[...tokens, member.name as string]}
                  member={{ name: key, type: 'boolean' }}
                  fieldConditions={fieldConditions}
                  externalFields={externalFields}
                  injectedFields={injectedFields}
                  forbiddenValues={forbiddenValues}
                />
              )}
            </Box>
            <Box ml={1}>
              <IconButton onClick={() => handleRemoveItem(key)} size="large">
                <DeleteIcon />
              </IconButton>
            </Box>
          </>
        )}
        {member.type === 'mapOfStructure' && (
          <Box marginBottom={1}>
            {customMembers[key] ? customMembers[key]?.(control, [name, key].join('.'), !!member.required) : (
              <Card variant="outlined">
                <CardHeader
                  subheader={`${singlify(name)} (${key})`}
                  action={
                    <IconButton onClick={() => handleRemoveItem(key)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  }
                />
                <CardContent>
                  <StructureRenderer
                    member={{ ...member, name: key }}
                    tokens={[...tokens, member.name as string]}
                    fieldConditions={fieldConditions}
                    externalFields={externalFields}
                    injectedFields={injectedFields}
                    forbiddenValues={forbiddenValues}
                  />
                </CardContent>
              </Card>
            )}
          </Box>
        )}
      </Box>
    ))}
  </>;
};

const ListRenderer = ({ tokens, member, externalFields, injectedFields, fieldConditions, hideNames, forbiddenValues }:
  MagicRenderer) => {
  const name = useMemo(() => [...tokens, member.name].join('.'), [tokens]);
  const { control, setValue, watch } = useFormContext();

  const formData = watch();
  const items = get(formData, name, []) as unknown[];
  const setterSettings = useMemo(() => ({ shouldDirty: true, shouldTouch: true }), []);

  const handleAddItem = useCallback(() => setValue(name, [...items, '']), [name, items]);
  const handleRemoveItem = useCallback(
    (idx: number) => setValue(name, items.filter((_i, i) => i !== idx), setterSettings), [name, items],
  );

  const visibility = useMemo(
    () => getMagicFieldVisibility(name, fieldConditions, formData),
    [name, fieldConditions, formData],
  );

  const customRoot = useMemo(
    () => getExternalMagicFieldRenderer(name, externalFields),
    [name, externalFields],
  );

  const customMembers = useMemo(
    () => items.map((_i, idx) => getExternalMagicFieldRenderer([name, idx].join('.'), externalFields)),
    [name, items, externalFields],
  );

  if (!visibility) return <></>;
  if (customRoot) return customRoot(control, name, !!member.required);

  useEffect(() => {
    if (member.required && !items.length) {
      handleAddItem();
    }
  }, []);

  return <>
    <ActionTitle
      title={<MemberTitle member={member} hideName={hideNames} />}
      actions={
        <IconButton onClick={handleAddItem} size="large">
          <AddIcon />
        </IconButton>
      }
    />
    {items.map((_item, idx) => (
      // eslint-disable-next-line
      <React.Fragment key={idx}>
        {['listOfString', 'listOfDouble'].includes(member.type) && (
          <Box marginBottom={1} display="flex" alignItems="center">
            <Box flexGrow={1}>
              {customMembers[idx] ? customMembers[idx]?.(control, [name, idx].join('.'), !!member.required) : (
                <PrimitiveRenderer
                  tokens={[...tokens, member.name as string]}
                  member={{ name: idx.toString(), type: member.type.toLocaleLowerCase().replace('listof', '') }}
                  fieldConditions={fieldConditions}
                  externalFields={externalFields}
                  injectedFields={injectedFields}
                  forbiddenValues={forbiddenValues}
                />
              )}
            </Box>
            <Box marginLeft={1}>
              <IconButton onClick={() => handleRemoveItem(idx)} size="large">
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        )}
        {member.type === 'listOfStructure' && (
          <Box marginBottom={1}>
            {customMembers[idx] ? customMembers[idx]?.(control, [name, idx].join('.'), !!member.required) : (
              <Card variant="outlined">
                <CardHeader
                  subheader={`${singlify(name)} ${idx + 1}`}
                  action={
                    <IconButton onClick={() => handleRemoveItem(idx)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  }
                />
                <CardContent>
                  <StructureRenderer
                    member={{ ...member, name: idx.toString() }}
                    tokens={[...tokens, member.name as string]}
                    fieldConditions={fieldConditions}
                    externalFields={externalFields}
                    injectedFields={injectedFields}
                    forbiddenValues={forbiddenValues}
                  />
                </CardContent>
              </Card>
            )}
          </Box>
        )}
      </React.Fragment>
    ))}
  </>;
};

const PrimitiveRenderer = ({ tokens, member, externalFields, fieldConditions, forbiddenValues }: MagicRenderer) => {
  const name = useMemo(() => [...tokens, member.name].join('.'), [tokens]);

  const { control, watch } = useFormContext();
  const formData = watch();

  const visibility = useMemo(
    () => getMagicFieldVisibility(name, fieldConditions, formData),
    [name, fieldConditions, formData],
  );

  const forbiddenValue = forbiddenValues && getFieldForbiddenValue(name, forbiddenValues);

  const custom = useMemo(
    () => getExternalMagicFieldRenderer(name, externalFields),
    [name, externalFields],
  );
  if (!visibility) return <></>;
  if (custom) return custom(control, name, !!member.required, formData);

  const rules = {
    ...(member.required ? VALIDATION_RULES.required : undefined),
    validate: forbiddenValue ? VALIDATION_RULES.notEqualsTo(forbiddenValue) : undefined,
  };

  return (
    <>
      {member.type === 'string' && !member.enum && (
        <ControlledTextField
          fullWidth
          variant="outlined"
          control={control}
          required={member.required}
          name={name}
          label={transformMagicFieldName(member.name)}
          rules={rules}
        />
      )}
      {member.type === 'string' && member.enum && (
        <ControlledSelect
          variant="outlined"
          control={control}
          required={member.required}
          fullWidth
          label={transformMagicFieldName(member.name)}
          name={name}
          options={member.enum.map((e) => <MenuItem key={e} value={e}>{e}</MenuItem>)}
          rules={rules}
        />
      )}
      {['double', 'long', 'integer'].includes(member.type) && (
        <ControlledTextField
          variant="outlined"
          type="number"
          control={control}
          required={member.required}
          fullWidth
          label={transformMagicFieldName(member.name)}
          name={name}
          rules={rules}
        />
      )}
      {member.type === 'boolean' && (
        <ControlledCheckbox
          name={name}
          color="primary"
          control={control}
        />
      )}
      {member.type === 'blob' && (
        <ControlledDropzone
          name={name}
          control={control}
          rules={rules}
        />
      )}
    </>
  );
};

export const StructureRenderer = ({
  tokens,
  member,
  externalFields,
  injectedFields,
  fieldConditions,
  hideNames,
  forbiddenValues,
}: MagicRenderer): ReactElement => {
  const { control, watch } = useFormContext();
  const formData = watch();

  // name can be empty for the root-level entry
  const name = useMemo(() => (member.name ? [...tokens, member.name] : tokens).join('.'), [member.name, tokens]);

  const visibility = useMemo(
    () => getMagicFieldVisibility(name, fieldConditions, formData),
    [name, fieldConditions, formData],
  );

  const custom = useMemo(
    () => getExternalMagicFieldRenderer(name, externalFields),
    [name, externalFields],
  );

  const memberVisibilities = useMemo(
    () => (member.members || []).map(
      (m) => getMagicFieldVisibility((name ? [name, m.name] : [m.name]).join('.'), fieldConditions, formData),
    ),
    [name, fieldConditions, formData, member.members],
  );

  if (!visibility) return <></>;
  if (custom) return custom(control, name, !!member.required);

  return (
    <Grid container spacing={3}>
      {(member.members || []).filter((_m, idx) => memberVisibilities[idx]).map((m, idx) => {
        const injectedField = getInjectedFieldsRenderer(name ? [name, m.name].join('.') : m.name, injectedFields);
        return (
          <React.Fragment key={m.name}>
            {injectedField && injectedField.position === 'before' && <>{injectedField.element}</>}
            {['string', 'boolean', 'double', 'long', 'integer', 'blob'].includes(m.type) && (
              <>
                <Grid item md={6} sm={12}>
                  <MemberTitle member={m} hideName={hideNames} />
                </Grid>
                <Grid item md={6} sm={12}>
                  <PrimitiveRenderer
                    tokens={member.name ? [...tokens, member.name] : tokens}
                    member={m}
                    externalFields={externalFields}
                    injectedFields={injectedFields}
                    fieldConditions={fieldConditions}
                    hideNames={hideNames}
                    forbiddenValues={forbiddenValues}
                  />
                </Grid>
              </>
            )}
            {m.type === 'structure' && (
              <Grid item xs={12}>
                <Box mb={2}>
                  <MemberTitle member={m} hideName={hideNames} />
                </Box>
                <Card variant="outlined">
                  <CardContent>
                    <StructureRenderer
                      tokens={member.name ? [...tokens, member.name] : tokens}
                      member={m}
                      externalFields={externalFields}
                      injectedFields={injectedFields}
                      fieldConditions={fieldConditions}
                      hideNames={hideNames}
                      forbiddenValues={forbiddenValues}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
            {m.type.startsWith('listOf') && (
              <Grid item xs={12}>
                <ListRenderer
                  tokens={member.name ? [...tokens, member.name] : tokens}
                  member={m}
                  externalFields={externalFields}
                  injectedFields={injectedFields}
                  fieldConditions={fieldConditions}
                  hideNames={hideNames}
                  forbiddenValues={forbiddenValues}
                />
              </Grid>
            )}
            {m.type.startsWith('mapOf') && (
              <Grid item xs={12}>
                <MapRenderer
                  tokens={member.name ? [...tokens, member.name] : tokens}
                  member={m}
                  externalFields={externalFields}
                  injectedFields={injectedFields}
                  fieldConditions={fieldConditions}
                  hideNames={hideNames}
                  forbiddenValues={forbiddenValues}
                />
              </Grid>
            )}
            {idx !== (member.members || []).length - 1 && m.type !== 'structure' && (
              <Grid item xs={12}>
                <Divider variant="fullWidth" orientation="horizontal" />
              </Grid>
            )}
            {injectedField && injectedField.position === 'after' && <>{injectedField.element}</>}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export interface MagicFormProps<DT, SD> {
  schema: any; // eslint-disable-line
  entry: string;
  formId?: string;
  loading?: boolean;
  /** External fields allows you to add or overwrite a field of the form */
  externalFields?: MagicFormExternalFields;
  injectedFields?: MagicFormInjectedFields;
  fieldConditions?: MagicFieldConditions;
  defaultValues?: any; //eslint-disable-line
  data?: DT;
  forbiddenValues?: ForbiddenValuesType;
  onSubmit: (data: SD) => unknown;
  fieldsOrder?: Extract<keyof SD, string>[];
}

export const MagicForm = <DT, SD>({
  schema,
  entry,
  formId,
  externalFields,
  injectedFields,
  fieldConditions,
  defaultValues,
  data,
  forbiddenValues,
  onSubmit,
  fieldsOrder,
}: MagicFormProps<DT, SD>): ReactElement => {
  const [resolvedSchema, setResolvedSchema] = useState<Optional<ResolvedMagicSchema>>(null);

  const methods = useForm({ mode: 'all', defaultValues });

  const getObjectShapeFromSchema = (schemaObj: ResolvedMagicSchema[]) => {
    let obj: object = [];
    schemaObj.forEach(member => {
      const type = member.type.startsWith('listOf') || member.type.startsWith('mapOf') ? 'list' : member.type;
      obj = {
        ...obj, [member.name || '']: (
          member.type === 'structure'
            ?
            getObjectShapeFromSchema(member?.members || [])
            :
            type
        ),
      };
    });
    return obj;
  };

  const buildObjectFromSchema = (formData: object, schemaObj: object): object => {
    const result: Record<string, object> = {};
    Object.keys(schemaObj).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(formData, key)) {
        if (typeof schemaObj[key as keyof typeof schemaObj] === 'object') {
          result[key] =
            buildObjectFromSchema(formData[key as keyof typeof formData], schemaObj[key as keyof typeof schemaObj]);
        } else {
          result[key] = formData[key as keyof typeof formData];
        }
      }
    });
    return result;
  };



  const submitHandler = useCallback(
    (formData: object) => {
      if (!methods.formState.isValid) {
        return;
      }

      const shape = getObjectShapeFromSchema((resolvedSchema?.members || []));
      let processedFormData = buildObjectFromSchema(formData as object, shape);
      processedFormData = removeEmptyProperties(processedFormData);
      return onSubmit(processedFormData as unknown as SD);
    },
    [
      methods.formState.isValid,
      resolvedSchema?.members,
    ],
  );


  useEffect(() => {
    if (!schema || !entry) return;

    (async () => {
      if (typeof schema === 'string') {
        const { data: downloadedSchema } = await axios.get(schema);
        const resolvedMagicSchemaUnordered = resolveMagicType(downloadedSchema.shapes[entry], downloadedSchema);
        const resolvedMagicSchema = fieldsOrder ?
          {
            ...resolvedMagicSchemaUnordered,
            members: resolvedMagicSchemaUnordered?.members?.sort(orderBy(fieldsOrder)),
          }
          : resolvedMagicSchemaUnordered;
        setResolvedSchema(resolvedMagicSchema);

      } else {
        // no need of reordering if schema object is directly passed. Order prop will be ignored
        setResolvedSchema(resolveMagicType(schema.shapes[entry], schema));
      }
    })();
  }, [schema, entry]);

  useEffect(() => {
    if (data) methods.reset(data);
  }, [data]);

  configureResetHandler({ defaultValues, reset: methods.reset });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitHandler)} id={formId}>
        {resolvedSchema && (
          <StructureRenderer
            member={resolvedSchema}
            tokens={[]}
            fieldConditions={fieldConditions}
            externalFields={externalFields}
            injectedFields={injectedFields}
            forbiddenValues={forbiddenValues}
          />
        )}
      </form>
    </FormProvider>
  );
};

type ResetHandlerProps = {
  reset: (values?: unknown, options?: Record<string, boolean>) => void;
  defaultValues?: any; //eslint-disable-line
}


const configureResetHandler = ({ defaultValues, reset }: ResetHandlerProps): void => {
  const { setResetFn } = useBaseLayoutProvider();
  const resetFn = () => reset(defaultValues, { keepDefaultValues: true });

  useEffect(() => {
    setResetFn(() => resetFn);
  }, []);
};
