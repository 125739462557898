import { ReactElement, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Grid , Alert, List } from '@mui/material';

import { LoadingFragment } from '@localstack/ui';
import { CloudPodsService, useApiGetter } from '@localstack/services';

import { CloudPodVersion, ProductFeature } from '@localstack/types';

import { CustomerLayout } from '~/layouts';
import { useAuthProvider } from '~/hooks';

import { ServiceListEntry } from '../StackOverview/ServiceListEntry';
import { beautifyCloudControlResponse } from '../StackOverview/util';

import { LoadFromCloudPodCard } from './components/LoadFromCloudPodCard';


export const PodDetails = (): ReactElement => {
  const { name } = useParams<'name'>() as { name: string };

  const { hasFeature } = useAuthProvider();
  const hasAccessToEphemeralInstances = hasFeature(ProductFeature.FEATURE_COMPUTE);

  const [selectedPodVersion, setSelectedPodVersion] = useState<Optional<CloudPodVersion>>();

  const { data: pod, isLoading: isPodRetrieving } = useApiGetter(
    CloudPodsService,
    'getCloudPod',
    [name],
    {
      enable: !!name,
    });

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamVersion = queryParams.get('version') ?? undefined;

  return (
    <CustomerLayout
      title='Cloud Pod Details'
    >
      <Grid container spacing={3}>
        {!(isPodRetrieving || pod) && (
          <Grid item xs={12}>
            <Alert severity='warning'>
              Could not find Cloud Pod with name {name}
            </Alert>
          </Grid>
        )}
        <LoadingFragment
          loading={isPodRetrieving}
          variant='grid'
          size={1}
          height={319}
          gridItemBreakpoints={{ xs: 12 }}
        >
          <>
            <Grid item xs={12}>
              <LoadFromCloudPodCard
                podName={name}
                version={queryParamVersion}
                size='medium'
                enableBrowse={hasAccessToEphemeralInstances}
                mainAction='instance'
                showSkeletonLoad
                callbackVersionSelection={(podVersion: CloudPodVersion) => setSelectedPodVersion(podVersion)}
              />
            </Grid>
            {selectedPodVersion && selectedPodVersion.cloud_control_resources && (
              <Grid item xs={12}>
                <List disablePadding>
                  {Object.entries(
                    beautifyCloudControlResponse(JSON.parse(selectedPodVersion.cloud_control_resources)),
                  ).map(([service, resources]) =>
                    <ServiceListEntry key={service} serviceResAggregation={{ service, resources }} />,
                  )}
                </List>
              </Grid>
            )}
          </>
        </LoadingFragment>
      </Grid>
    </CustomerLayout>
  );
};
